import React, { useState, useEffect } from "react"

const GetDataPage = () => {
  
  // Client-side Runtime Data Fetching
  const [dataText, setDataText] = useState(0)
  useEffect(() => {
    // get data from api
    fetch(`https://www.collaborationnotcompetition.com/api/getmessage`,{mode: 'no-cors'})
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        console.log(resultData.text);
        console.log(resultData);
        setDataText(resultData.text)
      }) // 
  }, [])
  return (
    <h1>
      <p>Runtime Data: Some text from the API {dataText}</p>
    </h1>
  )
}
export default GetDataPage